<template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-card-title class="headline">
          {{ `${setting.id ? 'Update' : 'Add'}` }} Pre-registration
          <hr>
        </v-card-title>

      </v-toolbar>

      <v-card-text>
        <div class="row">
          <div class="col-6">
            <v-select label="Academic year" :items="academic_years"  outlined dense item-value="id" :error="$v.setting.academic_year_id.$error"
                      item-text="title"
                      v-model="setting.academic_year_id">
            </v-select>
            <span class="text-danger" v-if="$v.setting.academic_year_id.$error">This information is required
              </span>
          </div>


          <div class="col-6">
            <v-combobox
                v-model="setting.compulsory_courses"
                hide-selected outlined dense deletable-chips
                placeholder="Compulsory course" label="Compulsory course"
                multiple  small-chips>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Type the Course name and press <kbd>ENTER</kbd>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
            <!--            <span class="text-danger" v-if="$v.setting.compulsory_course.$error">Compulsory courses is required</span>-->
          </div>

          <div class="col-6">
            <v-select label="Shifts" :items="shifts" deletable-chips small-chips multiple outlined dense item-value="value" :error="$v.setting.shifts.$error"
                      item-text="title"
                      v-model="setting.shifts"></v-select>
            <span class="text-danger" v-if="$v.setting.shifts.$error">This information is required</span>
          </div>
          <div class="col-6">
            <v-select label="Academic background" :items="academic_backgrounds" deletable-chips small-chips multiple outlined dense :error="$v.setting.academic_backgrounds.$error"
                      v-model="setting.academic_backgrounds" item-value="value" item-text="title"></v-select>
            <span class="text-danger"
                  v-if="$v.setting.academic_backgrounds.$error">This information is required</span>
          </div>
          <div class="col-6">
            <v-combobox label="Recommended options" :items="recommended_options" small-chips deletable-chips multiple outlined dense :error="$v.setting.recommended_options.$error"
                        v-model="setting.recommended_options" item-value="value" item-text="title"></v-combobox>
            <span class="text-danger" v-if="$v.setting.recommended_options.$error">This information is required</span>
          </div>
          <div class="col-3">
            <v-text-field  outlined dense v-model="setting.symbol_no_prefix" :error="$v.setting.symbol_no_prefix.$error || noNumericCharsError" @blur="checkNoNumericChars"
                          label="Symbol no prefix"></v-text-field>
            <span class="text-danger" v-if="$v.setting.symbol_no_prefix.$error">This information is required</span>
            <span class="text-danger" v-if="noNumericCharsError">This information should not contain numeric characters</span>
          </div>

          <div class="col-3">
            <v-text-field  @keypress="isNumber($event)" type="number" outlined dense v-model="setting.eligible_marks_for_process" :error="$v.setting.eligible_marks_for_process.$error"
                          label="Marks above for further process"></v-text-field>
            <span class="text-danger" v-if="$v.setting.eligible_marks_for_process.$error">This information is required</span>
          </div>
          <div class="col-3">
              <v-text-field
                v-model="setting.admission_fee"
                outlined
                dense :error="$v.setting.admission_fee.$error"
                label="Admission fee" @keypress="isNumber($event)"
              ></v-text-field>
            <span class="text-danger" v-if="$v.setting.admission_fee.$error">This information is required</span>
          </div>
          <div class="col-12">
            <v-textarea
                v-model="setting.online_counselling"
                label="Online counselling"
               dense outlined
            ></v-textarea>
          </div>

          <div class="col-6">
            <v-file-input
              outlined
              dense
              v-model="setting.online_counselling_logo_file"
              @change="checkSize($event)"
              label="Online counselling logo"
              prepend-inner-icon="mdi-file"
            ></v-file-input>
            <span class="text-danger" v-if="sizeExceed">Please upload a file upto 20MB</span>
<!--            <span class="text-danger" v-if="$v.setting.online_counselling_logo.$error">This information is required</span>-->
            <img v-if="counsellingLogoPath" :src="counsellingLogoPath" class="mt-3 ml-3 border rounded" style="width:100px;height:100px;object-fit:contain;" alt="">

          </div>

          <div class="col-6">
            <v-file-input
                outlined
                dense
                v-model="setting.offer_letter_background_file"
                @change="checkSizeOfferLetter($event)"
                label="Offer letter background"
                prepend-inner-icon="mdi-file"
            ></v-file-input>
            <span class="text-danger" v-if="sizeExceed1">Please upload a file upto 20MB</span>

            <img v-if="offer_letter_backgroundPath" :src="offer_letter_backgroundPath" class="mt-3 ml-3 border rounded" style="width:100px;height:100px;object-fit:contain;" alt="">
            <!--            <span class="text-danger" v-if="$v.setting.online_counselling_logo.$error">This information is required</span>-->
          </div>

          <div class="col-6">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="setting.orientation_date"
                    label="Orientation date"
                    prepend-icon=""
                    prepend-inner-icon="mdi-calendar"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="setting.orientation_date"
                  no-title
                  :min="today"
                  @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </div>



          <div class="col-9">
            <label>Offer letter text</label>
            <ckeditor id="description" v-model="setting.offer_letter_text"
                      :config="editorConfig">
            </ckeditor>
          </div>
          <div class="col-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                       class="ma-2 text-lowercase"
                       color="success"
                       outlined
                       label
                       dark
                       v-bind="attrs"
                       v-on="on"
                       v-clipboard:copy="`{{full_name}}`"
                       v-clipboard:success="onCopy"
                       v-clipboard:error="onError"
                >
                   full_name
                </v-btn>
                <v-btn
                    class="ma-2 text-lowercase"
                    color="success"
                    outlined
                    label
                    dark
                    v-bind="attrs"
                    v-on="on"
                    v-clipboard:copy="`{{symbol_no}}`"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                >
                  Symbol no
                </v-btn>
              </template>
              <span>Copy</span>
            </v-tooltip>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <v-switch v-model="setting.enable_selectable_course_1" label="Selectable course 1"></v-switch>
          </div>
          <div class="col-9" v-if="setting.enable_selectable_course_1">
            <v-combobox small-chips deletable-chips chips multiple outlined dense v-model="setting.selectable_course_1"
                        label="Selectable courses 1"></v-combobox>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <v-switch v-model="setting.enable_selectable_course_2" label="Selectable course 2"></v-switch>
          </div>
          <div class="col-9" v-if="setting.enable_selectable_course_2">
            <v-combobox small-chips deletable-chips chips multiple outlined dense v-model="setting.selectable_course_2"
                        label="Selectable courses 2"></v-combobox>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <v-switch v-model="setting.enable_selectable_course_3" label="Selectable course 3"></v-switch>
          </div>
          <div class="col-9" v-if="setting.enable_selectable_course_3">
            <v-combobox small-chips deletable-chips chips multiple outlined dense v-model="setting.selectable_course_3"
                        label="Selectable courses 3"></v-combobox>
          </div>

        </div>
        <div class="row">
          <div class="col-3">
            <v-switch v-model="setting.enable_selectable_course_4" label="Selectable course 4"></v-switch>
          </div>
          <div class="col-9" v-if="setting.enable_selectable_course_4">
            <v-combobox small-chips deletable-chips multiple outlined dense v-model="setting.selectable_course_4"
                        label="Selectable courses 4"></v-combobox>
          </div>
        </div>
        <div class="row">
          <div class="col-6" v-if="extra_information">
            <v-switch v-model="extra_information.enable_award_eca" label="Enable Award and ECA"></v-switch>
            <div class="card" v-if="extra_information.enable_award_eca">
              <div class="card-body">
                <div class="row text-center" v-if="extra_information.enable_award_eca">
                  <div class="col-12 ">
                    <v-switch v-model="extra_information.award_and_eca.enabled_received_awards_certificates" label="Enabled received Awards & Certificates"></v-switch>
                  </div>
                  <div class="col-12">
                    <v-switch v-model="extra_information.award_and_eca.enabled_involvement_in_club_organizations" label="Enabled involvement in club,organization etc"></v-switch>
                  </div>
                  <div class="col-12">
                    <v-switch v-model="extra_information.award_and_eca.enabled_volunteered_organization" label="Enabled volunteered organization"></v-switch>
                  </div>
                  <div class="col-12">
                    <v-switch v-model="extra_information.award_and_eca.enabled_future_plans" label="Enabled future plans - college or university plans"></v-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6" v-if="extra_information">
            <v-switch v-model="extra_information.enable_additional_information" label="Enable additional information"></v-switch>
            <div class="card" v-if="extra_information.enable_additional_information">
              <div class="card-body">
                <div class="row text-center" v-if="extra_information.enable_additional_information">
                  <div class="col-12 ">
                    <v-switch v-model="extra_information.additional_information.enabled_applicant_repeated_class" label="Enabled applicant repeated class"></v-switch>
                  </div>
                  <div class="col-12">
                    <v-switch v-model="extra_information.additional_information.enabled_applicant_suspended" label="Enabled applicant suspended or dismissed from school/college"></v-switch>
                  </div>
                  <div class="col-12">
                    <v-switch v-model="extra_information.additional_information.enabled_applicant_have_any_health_issue" label="Enabled applicant have any health issue">

                    </v-switch>
                  </div>
                  <div class="col-12">
                    <v-switch v-model="extra_information.additional_information.enabled_applicant_take_medication" label="Enabled applicant take medication"></v-switch>
                  </div>
                  <div class="col-12">
                    <v-switch v-model="extra_information.additional_information.enabled_applicant_need_special_needs" label="Enabled applicant need special needs"></v-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <v-switch v-model="setting.enable_declaration" label="Show declaration"></v-switch>
          </div>
          <div class="col-3">
            <v-switch v-model="setting.enable_hostel_availability" label="Residential facility"></v-switch>
          </div>
          <div class="col-3">
            <v-switch v-model="setting.enable_transportation_facility" label="Transportation facility"></v-switch>
          </div>
          <div class="col-3">
            <v-switch v-model="setting.is_active" label="Status"></v-switch>
          </div>
        </div>
        <!--          <pre>{{ setting }}</pre>-->
      </v-card-text>
      <v-card-actions>
        <div class="row">

          <div class="col-12 text-right">
            <v-btn
                class="btn btn-standard cancel-btn"
                depressed
                @click="resetForm"
            >Cancel
            </v-btn>
            <v-btn
                class="text-white ml-2 btn btn-primary"
                depressed
                @click="createPreregistration"
                :loading="isBusy"
            >
              {{admissionId ? "Update" : "Save"}}
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
</template>


<script>

import {required,helpers} from "vuelidate/lib/validators";
import PreRegistrationSettingService
  from "@/core/services/level/program/pre-registration-setting/PreRegistrationSettingService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";

const preRegistration = new PreRegistrationSettingService();
const levelService = new LevelService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();

const noNumericChars = (value) => {
  const regex = /^[^0-9]*$/;
  return helpers.regex(value, regex);
};
export default {
  name: "pre-registration-setting",
  validations: {
    setting: {
      // 'level_id': {required},
      // 'program_id': {required},
      'academic_year_id': {required},
      'academic_backgrounds': {required},
        'shifts': {required},
      'recommended_options': {required},
      'symbol_no_prefix': {required},
      'admission_fee':{required},
      'eligible_marks_for_process':{required},
    }
  },

  data() {
    return {
      noNumericCharsError:false,
      menu: false,
      menu2: false,
      sizeExceed: false,
      sizeExceed1: false,
      isBusy: false,
      level: {},
      program: {},
      academic_years: [],
      today: new Date().toISOString().substr(0,10),
      setting: {
        'online_counselling':null,
        'academic_year_id': null,
          'compulsory_courses': null,
        'selectable_course_1': null,
        'selectable_course_2': null,
        'selectable_course_3': null,
        'selectable_course_4': null,
        'optional_courses': null,
        'shifts': null,
        'academic_backgrounds': null,
        'recommended_options': null,
        'enable_transportation_facility': 0,
        'enable_hostel_availability': 0,
        'enable_declaration': 1,
        'enable_selectable_course_4': 0,
        'enable_selectable_course_3': 0,
        'enable_selectable_course_2': 0,
        'enable_selectable_course_1': 0,
        'is_active': 1,
        'symbol_no_prefix': null,
        'manage_candidate_before': 0,
        'admission_fee':null,
        'eligible_marks_for_process':null,
        'online_counselling_logo_file': null,
        'orientation_date': null,
        'class_start_date': null,
        'offer_letter_text': null,
        'offer_letter_background_file': null,
      },
      counsellingLogoPath: null,
      offer_letter_backgroundPath: null,
      extra_information:{
        enable_award_eca:false,
        award_and_eca:{
          enabled_received_awards_certificates:false,
          enabled_involvement_in_club_organizations:false,
          enabled_volunteered_organization:false,
          enabled_future_plans:false
        },
        enable_additional_information:false,
        additional_information:{
          enabled_applicant_repeated_class:false,
          enabled_applicant_suspended:false,
          enabled_applicant_have_any_health_issue:false,
          enabled_applicant_take_medication:false,
          enabled_applicant_need_special_needs:false,
        }
      },
      levels: [],
      programs: [],
      shifts: [
        {title: "Day", value: 'day'},
        {title: "Morning", value: 'morning'},
        {title: "Evening", value: 'evening'},
      ],
      academic_backgrounds: [
        {title: "Class 8", value: 'class-8'},
        {title: "Class 9", value: 'class-9'},
        {title: "SEE", value: 'see'},
        {title: "SLC", value: 'slc'},
        {title: "SEE/Send-up/Grade X Final Exam", value: 'SEE/Send-up/Grade X Final Exam'},
        {title: "+2", value: '+2'},
        {title: "Bachelor", value: 'bachelor'},
      ],
      recommended_options: [
        "Student or College family",
        "Friend/s or acquaintance/s",
        "Relatives",
        "Website",
        "Seminar",
        "Newspaper"
      ],
      other_setting: {
        'is_active': null,
      },
      settings: [
          {
          'level': null,
          'program': null,
              'compulsory_course': null,
          'optional_course': null,
          'shift': null,
          'academic_background': null,
          'academic_year': null,
          'compulsory_course_options': null,
        }
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId;
    },
    admissionId() {
      return this.$route.params.admissionId
    }
  },
  mounted() {
    this.getAcademicYears();
    if(this.programId && this.admissionId) {
      this.getPreregistration();
    }
  },
  methods: {
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data
        if (this.setting.level_id) {
          this.getPrograms();
        }
      })
    },
    getPrograms() {
      programService.getAllByLevelId(this.setting.level_id).then(response => {
        this.programs = response.data;
      })
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academic_years = response.data;
      })
    },

    getPreregistration() {
      preRegistration.show(this.programId, this.admissionId).then(response => {
        this.setting = response.data.setting;
        if(this.setting.online_counselling_logo_path != null){
          this.counsellingLogoPath = this.setting.online_counselling_logo_path['thumb'];
        }
        if(this.setting.offer_letter_background_path != null){
          this.offer_letter_backgroundPath = this.setting.offer_letter_background_path['thumb'];
        }
        if(this.setting.extra_information && this.setting.extra_information != "null"){
          this.extra_information = JSON.parse(this.setting.extra_information)
        } else {
          this.extra_information = {
              enable_award_eca: false,
              award_and_eca: {
                enabled_received_awards_certificates: false,
                enabled_involvement_in_club_organizations: false,
                enabled_volunteered_organization: false,
                enabled_future_plans: false
              },
              enable_additional_information: false,
              additional_information: {
                enabled_applicant_repeated_class: false,
                enabled_applicant_suspended: false,
                enabled_applicant_have_any_health_issue: false,
                enabled_applicant_take_medication: false,
                enabled_applicant_need_special_needs: false
              }
          }
        }
      })
    },

    convertToFormData() {
      let formData = new FormData();
      for (let key in this.setting) {
        if (key === "online_counselling_logo_file" && this.setting[key] != null && this.setting[key] != undefined) {
          formData.append('online_counselling_logo_file', this.setting[key]);
        } else if (key === "offer_letter_background_file" && this.setting[key] != null && this.setting[key] != undefined) {
          formData.append('offer_letter_background_file', this.setting[key]);
        } else {
          if(this.setting[key]){
            formData.append(key,this.setting[key]);
          }
        }
      }
      return formData;
    },

    createPreregistration() {
      this.$v.$touch();
      this.checkNoNumericChars();
      if (this.$v.$error || this.noNumericCharsError) {
        setTimeout(() => {
          this.$v.$reset();
          this.isBusy = false
        }, 3000)
      } else {
        this.isBusy = true;
        this.setting.extra_information=JSON.stringify(this.extra_information);
       if (this.setting.id) {
          this.__update();
        } else {
          this.__create();
        }
      }
    },
    __create() {
      const formData = this.convertToFormData();

      preRegistration.store(this.programId, formData).then(response => {
        if (!response.data.status)
          this.$snotify.error(response.data.msg)
        else
          this.resetForm();
        this.isBusy = false
      }).catch(error => {
        this.isBusy = false
        this.$snotify.error('Something went wrong')
      })
    },
    __update() {
      const formData = this.convertToFormData();

      preRegistration.update(this.programId, this.setting.id, formData).then(response => {
        this.resetForm();
        this.isBusy = false
      }).catch(error => {
        this.isBusy = false
        this.$snotify.error('Something went wrong')
      })
    },


    buildData() {
      let other_setting = this.settings;
      if (setting.levels && setting.levels.length > 0) {
        setting.levels = setting.levels.join("|")
      }
      if (setting.programs && setting.programs.length > 0) {
        setting.programs = setting.programs.join("|")
      } else {
        setting.programs = null
      }
      if (setting.compulsory_courses && setting.compulsory_courses.length > 0) {
        setting.compulsory_courses = setting.compulsory_courses.join("|")
      } else {
        setting.compulsory_courses = null
      }
      if (setting.optional_courses && setting.optional_courses.length > 0) {
        setting.optional_courses = setting.optional_courses.join("|")
      } else {
        setting.optional_courses = null
      }
      if (setting.shits && setting.shits.length > 0) {
        setting.shits = setting.shits.join("|")
      }
      if (setting.academic_backgrounds && setting.academic_backgrounds.length > 0) {
        setting.academic_backgrounds = setting.academic_backgrounds.join("|")
      }
      return setting;
    },
    addPreregistration() {
      this.settings.push({
        'level': null,
        'program': null,
         'compulsory_course': null,
        'optional_course': null,
        'shift': null,
      })
    },

    resetForm() {
      this.isBusy = false
      this.$v.$reset()
      this.setting = {
        'level_id': null,
        'program_id': null,
        'academic_year_id': null,
        'compulsory_courses': null,
        'selectable_course_1': null,
        'selectable_course_2': null,
        'selectable_course_3': null,
        'selectable_course_4': null,
        'optional_courses': null,
        'shifts': null,
        'academic_backgrounds': null,
        'recommended_options': null,
        'orientation_date': null,
        'class_start_date': null,
        'offer_letter_text': null,
        'offer_letter_background': null,
        'enable_transportation_facility': 0,
        'enable_hostel_availability': 0,
        'enable_declaration': 1,
        'enable_selectable_course_4': 0,
        'enable_selectable_course_3': 0,
        'enable_selectable_course_2': 0,
        'enable_selectable_course_1': 0,
        'is_active': 1,
      };
      this.extra_information={
        enable_award_eca:false,
            award_and_eca:{
          enabled_received_awards_certificates:false,
              enabled_involvement_in_club_organizations:false,
              enabled_volunteered_organization:false,
              enabled_future_plans:false
        },
        enable_additional_information:false,
            additional_information:{
              enabled_applicant_repeated_class:false,
              enabled_applicant_suspended:false,
              enabled_applicant_have_any_health_issue:false,
              enabled_applicant_take_medication:false,
              enabled_applicant_need_special_needs:false,
        }
      };
      this.$tabs.close().then(() => {
        this.$tabs.open({name: "program-update", params: {id: this.programId} })
      });
    },
    getProgram() {
      programService.show(this.programId, this.admissionId).then(response => {
        this.program = response.data.program
        this.setting.level_id = this.program.level_id
        this.setting.program_id = this.program.id
      })
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    checkSize(file) {
      if(this.setting.online_counselling_logo_file != null){
        this.counsellingLogoPath= URL.createObjectURL(this.setting.online_counselling_logo_file);
      } else{
        this.counsellingLogoPath = null;
      }

      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      this.sizeExceed = mbSize > 20;
    },
    checkSizeOfferLetter(file) {

      if (this.setting.offer_letter_background_file != null){
        this.offer_letter_backgroundPath = URL.createObjectURL(this.setting.offer_letter_background_file);
      }else{
        this.offer_letter_backgroundPath = null;
      }
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      this.sizeExceed1 = mbSize > 20;
    },
    onCopy: function (e) {
      this.text = "You just copied accepted input ";
      this.snackbar = true;
    },
    onError: function (e) {
      this.text = "Failed to copy accepted input";
      this.snackbar = true;
    },
    checkNoNumericChars(){
      const regex = /^[^0-9]*$/;
      this.noNumericCharsError = !regex.test(this.setting.symbol_no_prefix);
    },
  }
}
</script>

<style scoped>

</style>
